import { useCallback, useMemo } from 'react';
import { ShippingAddress, ShippingAddressValidationResult } from '@remento/types/shipping';

import { ShippingAddressValidationDialog } from '../components/ShippingAddressValidationDialog/ShippingAddressValidationDialog.js';

export interface ShippingAddressErrorDialogContainerProps {
  input: ShippingAddress;
  validationResult: ShippingAddressValidationResult;
  supportMessageSuffix: string;
  onSave: (address: ShippingAddress) => void;
  onClose: () => void;
}

export function ShippingAddressValidationDialogContainer({
  input,
  validationResult,
  supportMessageSuffix,
  onSave,
  onClose,
}: ShippingAddressErrorDialogContainerProps) {
  const error = useMemo(() => {
    if (validationResult.type === 'failed') {
      return 'UNKNOWN';
    }
    if (validationResult.warnings.length > 0) {
      return validationResult.warnings[0].code;
    }
    return null;
  }, [validationResult]);

  const resolvedAddress = useMemo<ShippingAddress | null>(() => {
    if (validationResult.type === 'failed' || validationResult.warnings.length > 0) {
      return null;
    }
    return {
      recipientName: input.recipientName,
      line1: validationResult.resolvedAddress.streetLines[0],
      line2: validationResult.resolvedAddress.streetLines[1] ?? '',
      city: validationResult.resolvedAddress.city,
      state: validationResult.resolvedAddress.stateOrProvinceCode ?? '',
      country: input.country,
      postalCode: validationResult.resolvedAddress.postalCode,
    };
  }, [input, validationResult]);

  const handleContactSupport = useCallback(() => {
    const email = 'support@remento.co';
    const subject = 'Shipping address validation error';

    const body = [
      'Dear Team Remento,',
      '',
      supportMessageSuffix,
      '',
      'I confirm that the following address is valid:',
      '',
      `Recipient name: ${input.recipientName}`,
      `Address line 1: ${input.line1}`,
      `Address line 2: ${input.line2 ?? 'N/A'}`,
      `City/Town: ${input.city}`,
      `State/Region/Province: ${input.state}`,
      `Zip/Post code: ${input.postalCode}`,
      `Country: ${input.country}`,
      '',
      `Error: ${error}`,
    ].join('\n');

    // Encode the subject and body to ensure the mailto link is properly formatted
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.open(mailtoLink);
  }, [error, input, supportMessageSuffix]);

  return (
    <ShippingAddressValidationDialog
      input={input}
      error={error}
      resolvedAddress={resolvedAddress}
      onSave={onSave}
      onClose={onClose}
      onEditAddress={onClose}
      onContactSupport={handleContactSupport}
    />
  );
}
