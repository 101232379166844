import { useMemo } from 'react';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ShippingAddress, ShippingAddressValidationErrorCode } from '@remento/types/shipping';
import AddressFormatter from 'i18n-postal-address';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMCloseButton } from '@/components/RMCloseButton/RMCloseButton';
import { RMDialog } from '@/components/RMDialog';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';

import {
  AddressBox,
  BodyContent,
  HeaderTitleWrapper,
  ShippingAddressText,
  SuggestedAddressBox,
} from './ShippingAddressValidationDialog.styles.js';

const DEFAULT_ERROR_MESSAGE = 'We could not find this address in our database.';
const ERROR_MESSAGES: Partial<Record<ShippingAddressValidationErrorCode, string>> = {
  'STANDARDIZED.ADDRESS.NOTFOUND': DEFAULT_ERROR_MESSAGE,
  'INVALID.SUITE.NUMBER': 'Invalid or missing apartment or suite number.',
  'SUITE.NUMBER.REQUIRED': 'Invalid or missing apartment or suite number.',
  'PARTIAL.STREET.MATCH': 'The entered address is a street number range.',
  'MISSING.OR.AMBIGUOUS.DIRECTIONAL': 'Invalid or missing address directional (e.g., N, S, NW).',
  'INTERPOLATED.STREET.ADDRESS':
    'Unable to confirm exact street number for the entered street name. The address falls within a valid range for the street name.',
  'RRHC.CONVERSION': 'The address has been converted from a rural route.',
};

const CONTINUABLE_ERRORS = new Set<ShippingAddressValidationErrorCode>([
  'INTERPOLATED.STREET.ADDRESS',
  'RRHC.CONVERSION',
]);

export interface ShippingAddressValidationDialogProps {
  input: ShippingAddress;
  error: ShippingAddressValidationErrorCode | 'UNKNOWN' | null;
  resolvedAddress: ShippingAddress | null;
  onSave: (address: ShippingAddress) => void;
  onEditAddress: () => void;
  onContactSupport: () => void;
  onClose: () => void;
}

export function ShippingAddressValidationDialog({
  input,
  error,
  resolvedAddress,
  onSave,
  onEditAddress,
  onContactSupport,
  onClose,
}: ShippingAddressValidationDialogProps) {
  const secondaryButtonLabel = useMemo(() => {
    if (error == null) {
      return 'Edit address';
    }

    if (error != null && error != 'UNKNOWN' && CONTINUABLE_ERRORS.has(error)) {
      return 'Continue with this address';
    }

    return 'Contact support';
  }, [error]);

  const formattedInput = useMemo(() => {
    const addressFormatter = new AddressFormatter({
      address1: input.line1,
      address2: input.line2 ?? undefined,
      city: input.city,
      state: input.state,
      postalCode: input.postalCode,
      country: input.country,
    });
    return addressFormatter.toString();
  }, [input]);

  const formattedResolvedAddress = useMemo(() => {
    if (resolvedAddress == null) {
      return null;
    }
    const addressFormatter = new AddressFormatter({
      address1: resolvedAddress.line1,
      address2: resolvedAddress.line2 ?? undefined,
      city: resolvedAddress.city,
      state: resolvedAddress.state,
      postalCode: resolvedAddress.postalCode,
      country: resolvedAddress.country,
    });
    return addressFormatter.toString();
  }, [resolvedAddress]);

  return (
    <RMDialog.Root open onClose={onClose}>
      <RMDialog.Content>
        <RMDialog.Header
          title={
            error != null ? (
              <HeaderTitleWrapper>
                <FontAwesomeIcon icon={faTriangleExclamation} color="var(--danger)" />
                Shipping address error
              </HeaderTitleWrapper>
            ) : (
              'Confirm your address'
            )
          }
          message={
            error != null
              ? 'Our postal provider doesn’t recognize the address you entered.'
              : 'We could not verify the exact address you entered, but we found a close match. Please edit or confirm your address.'
          }
          rightAdornment={<RMCloseButton onClick={onClose} />}
        />
        <RMDialog.Body>
          <BodyContent>
            {error != null && (
              <>
                <RMText type="sans" size="xs" color="on-surface-secondary" bold>
                  Error message
                </RMText>
                <RMText type="sans" size="xs" color="on-danger-container">
                  {error != 'UNKNOWN' && ERROR_MESSAGES[error] != null ? ERROR_MESSAGES[error] : DEFAULT_ERROR_MESSAGE}
                </RMText>
                <RMSpacer direction="column" spacing="md" />
              </>
            )}
            <RMText type="sans" size="xs" color="on-surface-secondary" bold>
              You entered
            </RMText>
            <AddressBox>
              <ShippingAddressText type="sans" size="s" color="on-surface-tertiary">
                {formattedInput}
              </ShippingAddressText>
            </AddressBox>
            {resolvedAddress != null && (
              <>
                <RMSpacer direction="column" spacing="md" />
                <RMText type="sans" size="xs" color="on-surface-secondary" bold>
                  Suggested address
                </RMText>
                <SuggestedAddressBox>
                  <ShippingAddressText type="sans" size="s" color="on-surface-tertiary">
                    {formattedResolvedAddress}
                  </ShippingAddressText>
                </SuggestedAddressBox>
              </>
            )}
          </BodyContent>
        </RMDialog.Body>
        <RMDialog.Footer>
          <RMButton
            autoLoading
            fullWidth
            onClick={() => {
              if (error == null) {
                return onEditAddress();
              }
              if (error != 'UNKNOWN' && CONTINUABLE_ERRORS.has(error)) {
                return onSave(input);
              }
              return onContactSupport();
            }}
          >
            {secondaryButtonLabel}
          </RMButton>
          <RMButton
            autoLoading
            fullWidth
            background="primary"
            onClick={() => {
              if (resolvedAddress != null) {
                onSave(resolvedAddress);
                return;
              }
              onEditAddress();
            }}
          >
            {resolvedAddress != null ? 'Save and continue' : 'Update address'}
          </RMButton>
        </RMDialog.Footer>
      </RMDialog.Content>
    </RMDialog.Root>
  );
}
