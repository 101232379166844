import isEqual from 'react-fast-compare';
import { ShippingAddress, ShippingAddressValidationResult } from '@remento/types/shipping';

export function shouldShowShippingValidationDialog(
  address: ShippingAddress,
  validationResult: ShippingAddressValidationResult,
): boolean {
  if (validationResult.type == 'failed') {
    return true;
  }

  if (validationResult.warnings.length > 0) {
    return true;
  }

  const resolvedAddress: ShippingAddress = {
    recipientName: address.recipientName,
    line1: validationResult.resolvedAddress.streetLines[0],
    line2: validationResult.resolvedAddress.streetLines[1] ?? '',
    city: validationResult.resolvedAddress.city,
    state: validationResult.resolvedAddress.stateOrProvinceCode ?? '',
    country: address.country,
    postalCode: validationResult.resolvedAddress.postalCode,
  };

  return !isEqual(address, resolvedAddress);
}
